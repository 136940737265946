import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainCol from "./MainCol";
import ContentCol from "./ContentCol";
import ServicesCol from "./ServicesCol";
import ContactCol from "./ContactCol";
import CopyRight from "./CopyRight";

export default function Footer() {
  return (
    <footer className="secondary-background text-light text-center">
      <Container className=" py-5">
        <Row>
          <Col lg={3} md={6} sm={12}>
            <MainCol />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <ContentCol />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <ServicesCol />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <ContactCol />
          </Col>
        </Row>
      </Container>
      <CopyRight />
    </footer>
  );
}
