import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import about from "../../assets/images/cdfb658a-098f-4df7-8dde-ca69e97a3404.jpeg";

export default function About() {
  return (
    <section id="about" className="my-5">
      <Container>
        <Row className="align-items-center">
          <Col
            lg={6}
            md={4}
            sm={12}
            className="align-items-center d-flex flex-column"
          >
            <img src={about} alt="" className="img-fluid about-img shadow" />
            <caption>Ayman Bayoumy</caption>
          </Col>
          <Col lg={6} md={8} sm={12}>
            <h2 className="fs-2 my-4 fw-bold main-color text-center">
              Your way to America
            </h2>
            <p className="secondary-color text-right">
              ايمن عبد الصادق بيومي مؤسس شركة طريقك الي أمريكا. اخصائي علاج
              طبيعي يحمل الجنسية الامريكية منذ عام ١٩٩٦ ومهتم بشؤون المهاجرين
              العرب في أمريكا منذ عام ٢٠٢٠ خلال جائحة الكورونا قام واخرون بانشاء
              اتحاد جروبات الهجرة لامريكا لدعم المهاجرون العرب العالقين في
              امريكا وامتدت خدماتهم لمساعدة المهاجرين العرب القانونيين للوصول
              الي امريكا والانخراط في مجتمعهم الجديد والنجاح فيه… طريقك الي
              امريكا هو بوابتك لإتمام اجراءات الهجرة العشوائية لامريكا.. نحن
              نساعدك في التقديم ونقوم عنك بأكمال كل اجراءات التحضير لمقابلة
              الهجرة العشوائية لامريكا بما في ذلك تعبئة نماذج ما بعد الفوز مثل
              نموذج دي إس ٢٦٠ وما شابه… شامل في خدماتنا ايضا توفير عنوان استقبال
              الجرين كارد الخاص بك بامريكا وارساله لك في مكان اقامتك و نقوم عنك
              بكل المكاتبات لادارة الهجرة ومركز كنتاكي وتقديم طلب تحويل مقابلتك
              من دولة الي اخري اذا لزم الامر… واخيرا نقوم بتاخطارك بما هو جديد
              عن الهجرة العشوائية الخاصة بك حتي تتفرغ لعملك..تابع معنا الان
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
