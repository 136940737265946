import React from "react";
import "react-awesome-slider/dist/styles.css";
import hero1 from "../../assets/images/hero1.jpg";
import hero2 from "../../assets/images/hero2.jpg";
import hero3 from "../../assets/images/hero3.jpg";
import "./home.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { TypeAnimation } from "react-type-animation";
export default function Hero() {
  const images = [
    {
      original: hero1,
    },
    {
      original: hero2,
    },
    {
      original: hero3,
    },
  ];
  return (
    <div className="position-relative" id="hero">
      <div className="position-absolute z-2 top-50 start-50 translate-middle text-light fw-bolds  p-3 rounded-2">
        <TypeAnimation
          sequence={[
            "طريقك",
            1000,
            "طريقك الي",
            1000,
            "طريقك الي أمريكا",
            1000,
            "طريقك الي",
            1000,
          ]}
          speed={50}
          style={{ fontSize: "1em", fontWeight: "bold" }}
          repeat={Infinity}
          rtl={true}
        />
      </div>
      <ImageGallery
        items={images}
        id="hero"
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        showNav={false}
        autoPlay={true}
        originalWidth={"100%"}
      />
    </div>
  );
}
