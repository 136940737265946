import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Heading from "../utilities/Heading";
import { Link, useLocation } from "react-router-dom";

export default function UsaProcess() {
  const rel = useLocation().pathname;
  // console.log();

  return (
    <section id="usa-process" className="my-5 text-center py-5">
      <Container>
        <Heading
          title={"خطوات التأهيل لتأشيرة السياحة الامريكية بواسطتنا"}
          subtitle={"How We Work"}
        />
        <Row className="mt-2 flex-row-reverse align-items-between justify-content-center mx-auto">
          <Col
            className="mt-5 d-flex align-items-center gap-3 flex-row-reverse p-0 justify-content-start"
            lg={4}
            md={4}
            sm={12}
          >
            <span className="rounded-5 main-background py-3 px-4 text-light fw-bold">
              1
            </span>
            <p className="fs-4 fw-bold secondary-color">تواصل معنا</p>
          </Col>
          <Col
            className="mt-5 d-flex align-items-center gap-3 flex-row-reverse p-0 justify-content-start"
            lg={4}
            md={4}
            sm={12}
          >
            <span className="rounded-5 main-background py-3 px-4 text-light fw-bold">
              2
            </span>
            <p className="fs-4 fw-bold secondary-color">تحضير الاوراق</p>
          </Col>
          <Col
            className="mt-5 d-flex align-items-center gap-3 flex-row-reverse p-0 justify-content-start"
            lg={4}
            md={4}
            sm={12}
          >
            <span className="rounded-5 main-background py-3 px-4 text-light fw-bold">
              3
            </span>
            <p className="fs-4 fw-bold secondary-color">
              دفع الرسوم وتحضير المقابلة
            </p>
          </Col>
        </Row>
        {rel !== "/Tourist-Visa" && (
          <Button className="btn btn-danger mt-5">
            <Link to={"/Tourist-Visa"}>اعرف اكتر من هنا</Link>
          </Button>
        )}
      </Container>
    </section>
  );
}
