import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "../../assets/images/Red-and-Blue-Simple-Travel-Logo-3.png";
import { Link } from "react-router-dom";
import { data } from "../../data/Contact";
export default function MainBar() {
  return (
    <Navbar expand="lg" className="main-background">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={Logo} alt="" className="main-logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="text-body-secondary border-body-secondary"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ms-3 gap-3">
            <Nav.Link href="">
              <Link className="text-light" to="/">
                الرئيسية
              </Link>
            </Nav.Link>
            <Nav.Link href="">
              <Link className="text-light" to={"/Lottery-Visa"}>
                قدم معانا للهجرة العشوائية لأمريكا
              </Link>
            </Nav.Link>
            <Nav.Link href="">
              <Link className="text-light" to={"/Tourist-Visa"}>
                فيزا السياحة لأمريكا
              </Link>
            </Nav.Link>
          </Nav>
          <a
            className="btn btn-outline-light my-2 mx-auto"
            href={data.whatsapp}
            target="_blank"
            rel="noreferrer"
          >
            تواصل معنا
          </a>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
