import React from "react";

export default function Heading({ title, subtitle }) {
  return (
    <>
      <p className="fs-5 secondary-color text-center">{subtitle}</p>
      <h2 className="fs-2 fw-bold main-color mb-3 text-center">{title}</h2>
    </>
  );
}
