import React from "react";

export default function ContentCol() {
  return (
    <div>
      <h3 className="fw-bold mb-4  mt-3">Home Content</h3>
      <ul className="gap-2 d-flex flex-column fs-5 p-0 mb-5">
        <li>
          <a href="#about" className="main-hover">
            about
          </a>
        </li>
        <li>
          <a href="#services" className="main-hover">
            services
          </a>
        </li>
        <li>
          <a href="#video" className="main-hover">
            Latest Videos
          </a>
        </li>
        <li>
          <a href="#usa-process" className="main-hover">
            Package
          </a>
        </li>
        <li>
          <a href="#contact" className="main-hover">
            contact
          </a>
        </li>
        <li>
          <a href="#faq" className="main-hover">
            FAQ
          </a>
        </li>
      </ul>
    </div>
  );
}
