import React from "react";
import Hero from "../../components/home/Hero";
import About from "../../components/home/About";
import Services from "../../components/home/Services";
import Video from "../../components/home/Video";
import UsaProcess from "../../components/home/UsaProcess";
import ContactHome from "../../components/home/ContactHome";
import FAQ from "../../components/home/FAQ";
import ScrollAnimation from "react-animate-on-scroll";

export default function HomePage() {
  return (
    <div>
      <ScrollAnimation animateIn="fadeIn">
        <Hero />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <About />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <Services />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <Video />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <UsaProcess />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <ContactHome />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <FAQ />
      </ScrollAnimation>
    </div>
  );
}
