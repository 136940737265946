import React from "react";

export default function CopyRight() {
  return (
    <div className="border-top py-2 text-center">
      <p className="m-0">
        Copyright 2024 © yourwaytoamerica.com All Right Reserved Design by{" "}
        <a
          className="main-hover fw-bold"
          href="https://mohamedelbahrawy.vercel.app/"
        >
          MB-Dev
        </a>
      </p>
    </div>
  );
}
