import React from "react";
import Heading from "../utilities/Heading";
import { Button, Container } from "react-bootstrap";

export default function LotteryForm() {
  return (
    <div className="d-flex flex-column justify-content-start align-items-center">
      <Heading title="قدم معنا على الهجرة العشوائية لأمريكا" />
      <Container className="my-5 border p-5 rounded-4 bg-body-tertiary text-center">
        <h4 className="fw-normal">أملي النوذج الاتي الخاص بالهجرة العشوائية</h4>
        <Button variant="success" className="mt-3">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeONdbCKbhrfget1G8rLZN9IpPo0IQdcXxa1vHWE3oI-J3b6g/viewform?usp=sf_link"
            target="_blank"
            rel="noreferrer"
          >
            أملي النموذج معانا الان
          </a>
        </Button>
      </Container>
    </div>
  );
}
