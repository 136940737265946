import React from "react";
import logo from "../../assets/images/Red-and-Blue-Simple-Travel-Logo-3.png";
export default function MainCol() {
  return (
    <div className="overflow-hidden">
      <img src={logo} alt="" className="img-fluid" />
      <ul className="d-flex gap-3 mt-3 justify-content-center p-0">
        <li>
          <a
            href="https://www.facebook.com/profile.php?id=100063484849151&mibextid=LQQJ4d"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-facebook fs-4 main-hover"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/your.way.to.america_ayman"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-instagram fs-4 main-hover"></i>
          </a>
        </li>
        <li>
          <a
            href="https://msng.link/o?Ayman_Bayoumy=tg"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-telegram fs-4 main-hover"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/@aymanpta1?si=jiTRCKJWmHrb1fLp"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa-brands fa-youtube fs-4 main-hover"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}
