import React from "react";
import Banner from "../../components/utilities/Banner";
import img from "../../assets/images/lottery.jpg";
import { Container } from "react-bootstrap";
import LotteryForm from "../../components/lottery/LotteryForm";
export default function LotteryPage() {
  return (
    <div>
      <Banner title="الهجرة العشوائية لامريكا - DV Lottery 2026" img={img} />
      <Container>
        <LotteryForm />
      </Container>
    </div>
  );
}
