import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Video() {
  return (
    <div id="video" className="">
      <Container>
        <Row className="justify-content-between align-items-center">
          <Col lg={7} md={12} sm={12}>
            <iframe
              width="100%"
              height="400"
              className="rounded-3"
              src="https://www.youtube.com/embed/wMZJC5GdEHQ"
              title="تحضير اوراق الفائزين في الهجرة العشوائية الامريكية ،السفر الي أمريكا ,نتيجة القرعة الامريكية 2025"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Col>
          <Col lg={4} md={12} sm={12}>
            <h3 className="fs-2 fw-bold text-center mt-4 secondary-color">
              تحضير اوراق الفائزين في الهجرة العشوائية الامريكية ،السفر الي
              أمريكا ,نتيجة القرعة الامريكية 2025
            </h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
