import React from "react";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

export default function FAQ() {
  return (
    <section id="faq" className="my-5">
      <Container>
        <h2 className="fs-2 my-5 text-center fw-bold">الأسئلة المعتادة</h2>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>هل استطيع السفر بدون الأسرة</Accordion.Header>
            <Accordion.Body>اذ كنت الفائز الرئيسي نعم</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>ما هي الهجرة العشوائية</Accordion.Header>
            <Accordion.Body>
              <pre
                style={{
                  fontFamily: "inherit",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  textAlign: "right",
                }}
              >
                <Accordion.Body>
                  {`
             الدولة المسموح لأفرادها بالتقديم على القرعة لنفس العام للمشاركة من أجل العمل أوالدراسة أو العيش في الولايات المتحدة الأمريكية وذلك ضمن برنامج الهجرة العشوائي للحصول على البطاقة الدائمة الجرين كارد

Diversity Visa

DV هي اختصار لـ Diversity Visa أي تأشيرة التنوع، وترجع تسميتها بهذا الاسم إلى السنة المالية التي توزع فيها الحكومة الأمريكية بطاقات الجرين كارد.
لمعرفة المزيد يرجي مراجعة الفيديوهات ادناه

https://youtu.be/XyH9pjveKK0?si=aPoCIXJFrfTyH2iz

https://youtu.be/1xctvaESHUg?si=CjwazpxXy8AIXdAd

لمطلوب منك للتسجيل عبر مكتبنا
الاسم بالكامل لكل افراد الاسرة حتي الاطفال
تاريخ الميلاد
الوظيفة
المؤهل

يستطيع الحاصلون على الجرين كارد السفر بحرية تامة من وإلى الولايات المتحدة لأنهم يعتبرون مثل المواطنين الدائمين على أرض الولايات المتحدة ويحق لهم بشكل قانوني العمل والاستفادة من خدمات الصحة والتعليم والضرائب والتقاعد والضمان الاجتماعي وغيرها من المساعدات، وبعد ذلك يمكن لحاملي بطاقة الجرين كارد التقدم للحصول على الجنسية الأمريكية دون أن يفقدوا جنسيتهم الحاصلين عليها من بلدهم التي ولدوا فيها، وتعتبر بطاقات الجرين كارد سارية المفعول لمدة عشر سنوات ويمكن تجديدها بأجراءات بسيطة
            `}
                </Accordion.Body>
              </pre>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              How long does it take to process a visa application?
            </Accordion.Header>
            <Accordion.Body>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts. Separated
              they live in Bookmarksgrove right at the coast
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Is it possible to work while on a student visa?
            </Accordion.Header>
            <Accordion.Body>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts. Separated
              they live in Bookmarksgrove right at the coast
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              What is the difference between a tourist & business visa?
            </Accordion.Header>
            <Accordion.Body>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts. Separated
              they live in Bookmarksgrove right at the coast
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              Can I apply for a visa without a job offer?
            </Accordion.Header>
            <Accordion.Body>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts. Separated
              they live in Bookmarksgrove right at the coast
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              How can I check the status of my visa application?
            </Accordion.Header>
            <Accordion.Body>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts. Separated
              they live in Bookmarksgrove right at the coast
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
}
