import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Services() {
  return (
    <section id="services" className="my-5 bg-body-tertiary py-5">
      <Container>
        <Row className="justify-content-evenly gap-5">
          <Col
            lg={5}
            md={5}
            sm={12}
            className="text-center border p-3 rounded-2 service-card"
          >
            <i class="fa-solid fa-comments fs-1 main-color"></i>
            <div className="service-text">
              <h3 className="my-3 fs-4 fw-bold">
                استشارات خاصة عن الهجرة العشوائية
              </h3>
              <p className="secondary-color">
                لحجز مكالمة علي الخاص عن طريق مكتبنا للاجابة عن كل استفساراتكم
                بخصوص السياحة في امريكا
              </p>
            </div>
          </Col>
          <Col
            lg={5}
            md={5}
            sm={12}
            className="text-center border p-3 rounded-2 service-card d-flex flex-column justify-content-center"
          >
            <i class="fa-solid fa-comments fs-1 main-color"></i>
            <div className="service-text">
              <h3 className="my-3 fs-4 fw-bold">التأكد من جميع اوراقك</h3>
            </div>
          </Col>
          <Col
            lg={5}
            md={5}
            sm={12}
            className="text-center border p-3 rounded-2 service-card"
          >
            <i class="fa-solid fa-comments fs-1 main-color"></i>
            <div className="service-text">
              <h3 className="my-3 fs-4 fw-bold">
                التأهل لتأشيرة أمريكا من خلالنا
              </h3>
              <p className="secondary-color">
                نعبئ لك نموزج دي اس ١٦٠ الخاص بتأشيرة السياحة لامريكا باحترافية
                ومهنية حتي نبرز جوانب القوة في ملفك مما يساعد علي حصولك علي
                التأشيرة..نقوم بتحضيرك للمقابلة وتدريبك علي الاسئلة المتوقعة
                اثناء المقابلة ولغة جسدك.. شامل في خدمة التأشيرات السياحية
                لامريكا حجز فندقي حقيقي مؤكد بأسمك ويأتيك التأكد مباشرة من
                الفندق علي ايميلك الخاص.. كما اننا نتعامل مع شركات سياحية في
                جميع ولايات امريكا لتحضير برنامج سياحي خاص بك وزيارة المعالم في
                الولاية المرغوبة باسعار المواصلات والوجبات وكيفية الوصول الي هذه
                الاماكن من الفندق والعكس حسب ميزانيتك الموضوعة للسياحة
              </p>
            </div>
          </Col>
          <Col
            lg={5}
            md={5}
            sm={12}
            className="text-center border p-3 rounded-2 service-card"
          >
            <i class="fa-solid fa-comments fs-1 main-color"></i>
            <div className="service-text">
              <h3 className="my-3 fs-4 fw-bold">الهجرة العشوائية</h3>
              <p className="secondary-color">
                هو برنامج ينظم في كل عام، وتمنح حكومة الولايات المتحدة الأمريكية
                55,000 تأشيرة فيزا للأفراد من الدول المؤهلة أي الدولة المسموح
                لأفرادها بالتقديم على القرعة لنفس العام للمشاركة من أجل العمل
                أوالدراسة أو العيش في الولايات المتحدة الأمريكية وذلك ضمن برنامج
                الهجرة العشوائي للحصول على البطاقة الدائمة الجرين كارد Diversity
                Visa لمعرفة المزيد يرجي مراجعة الفيديوهات ادناه
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
