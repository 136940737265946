import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import bg from "../../assets/images/around-the-world-travel-booking-vector-concept-6EY4VU.png";
import { data } from "../../data/Contact";
export default function ContactHome() {
  return (
    <section
      id="contact"
      className="secondary-background text-light py-5 position-relative overflow-hidden"
    >
      <img src={bg} alt="" />
      <Container>
        <Row className="justify-content-between align-items-center">
          <Col md={7} className="text">
            <h3 className="fs-2 mb-4">
              Please Contact us if you Have any Questions.
            </h3>
            <p>
              Have you got any inquiries? Write to us, and one of our experts
              will respond.
            </p>
          </Col>
          <Col
            md={4}
            className="buttons d-flex flex-column justify-content-end mt-4"
          >
            <button className="btn btn-success d-block mb-2 py-2">
              <a href={data.whatsapp} target="_blank" rel="noreferrer">
                تواصل من خلال واتس اب
              </a>
            </button>
            <button className="btn btn-primary py-2">
              <a href={data.email1} target="_blank" rel="noreferrer">
                تواصل من خلال البريد الالكتروني
              </a>
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
