import React from "react";
import { Container } from "react-bootstrap";
import { data } from "../../data/Contact";

export default function TopBar() {
  return (
    <div className="secondary-background text-light p-2">
      <Container className="d-flex justify-content-between align-items-center">
        <ul className="gap-4 m-0 p-0 d-flex">
          <li className="main-hover">
            <i class="fa-solid fa-map-location me-2"></i> USA
          </li>
          <li className="main-hover">
            <a href={data.email1} target="_blank" rel="noreferrer">
              <i class="fa-solid fa-envelope me-2"></i>
              {data.email1.split("mailto:")[1]}
            </a>
          </li>
        </ul>
      </Container>
    </div>
  );
}
