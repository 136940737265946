import React from "react";
import { data } from "../../data/Contact";

export default function ContactCol() {
  return (
    <div>
      <h3 className="fw-bold mb-4 mt-3">Contact Us</h3>
      <button className="btn btn-success d-block mb-2 py-2 mx-auto">
        <a href={data.whatsapp} target="_blank" rel="noreferrer">
          تواصل من خلال واتس اب
        </a>
      </button>
    </div>
  );
}
