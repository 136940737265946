import React from "react";
import UsaProcess from "../home/UsaProcess";
import Heading from "../utilities/Heading";
import { Col, Row } from "react-bootstrap";
import { data } from "../../data/Contact";

export default function TouristVisa() {
  return (
    <div>
      <article className="my-5">
        <UsaProcess />
        <Row className="justify-content-between align-items-center my-4">
          <Heading title="قدم معنا على فيزا سياحة امريكا" />
          <Col
            lg={4}
            md={6}
            sm={12}
            className="d-flex align-items-center flex-wrap gap-2 justify-content-center"
          >
            <button className="btn btn-success my-2 py-2">
              <a href={data.whatsapp} target="_blank" rel="noreferrer">
                واتس اب
              </a>
            </button>
            <button className="btn btn-primary py-2">
              <a href={data.email1} target="_blank" rel="noreferrer">
                البريد الالكتروني
              </a>
            </button>
          </Col>
          <Col
            lg={8}
            md={6}
            sm={12}
            className="fs-5"
            style={{ textAlign: "center", direction: "rtl" }}
          >
            تواصل معنا من خلال رقم الواتس اب او الايميل للتواصل بخصوص اجرائات
            الفيزا
          </Col>
        </Row>
      </article>
    </div>
  );
}
