import { Navigate, Route, Routes } from "react-router-dom";
import "animate.css/animate.compat.css";

import "./App.css";
import React from "react";
import Header from "./components/header/Header";
import HomePage from "./pages/home/HomePage";
import LotteryPage from "./pages/lottery/LotteryPage";
import TouristPage from "./pages/tourist/TouristPage";
import bg from "../src/assets/images/Map-Bg-2.png";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/utilities/ScrollToTop";
function App() {
  return (
    <div className="App ">
      <img src={bg} alt="" className="position-fixed opacity-50 bg" />
      <Header />
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/Lottery-Visa" element={<LotteryPage />} />
        <Route path="/Tourist-Visa" element={<TouristPage />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
