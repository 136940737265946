import React from "react";
import Banner from "../../components/utilities/Banner";
import img from "../../assets/images/tourist.jpeg";
import TouristVisa from "../../components/tourist/TouristVisa";
import { Container } from "react-bootstrap";

export default function TouristPage() {
  return (
    <div>
      <Banner title="قدم علي فيزا سياحة امريكا - B1/B2" img={img} />
      <Container>
        <TouristVisa />
      </Container>
    </div>
  );
}
